import Swal from "sweetalert2";

export const useSweetalert = () => {
  function showErrorToast(title, description) {
    Swal.fire({
      icon: "error",
      title: title,
      text: description,
      position: "top-end",
      timer: 1500,
      toast: true,
      showConfirmButton: false,
      showCancelButton: false,
    });
  }

  function showSuccessToast(title, description) {
    Swal.fire({
      icon: "success",
      title: title,
      text: description,
      position: "top-end",
      timer: 1500,
      toast: true,
      showConfirmButton: false,
      showCancelButton: false,
    });
  }

  return { showErrorToast, showSuccessToast };
};
