import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import BackToTop from 'vue-backtotop'
import 'swiper/swiper-bundle.css'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import '../src/assets/css/icofont.min.css'
import '../src/assets/css/gordita-font.css'
import '../src/assets/scss/style.scss'
import "notyf/notyf.min.css";
import "ant-design-vue/dist/reset.css";
import Antd from "ant-design-vue";

createApp(App).use(router).use(BackToTop).use(Antd).mount("#app");