<template>
    <!-- FAQ Area End Here -->
    <div class="faq-area faq-style-common faq-style-2">
        <div class="container">
            <div class="section-area">
                <h2 class="section-title">Preguntas y Respuestas</h2>
                <p class="section-desc">Resuelve tus posibles dudas acerca del proceso</p>
            </div>

            <div class="row">
                <div v-for="(order, index) in paginatedOrders" :key="index" class="col-lg-6">
                    <div class="faq-item-wrap row">

                        <div v-for="(item, index) in order.items" :key="index" class="col-12">
                            <div class="faq-item">
                                <h3 class="faq-title">{{item.title}}</h3>
                                <p class="faq-desc">{{item.desc}}</p>
                                <p class="faq-desc">{{item.desc2}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="currentPage * maxPerPage < orders.length">
                <div class="col-12">
                    <div class="faq-btn-wrap">
                        <a class="btn btn-custom-size extbot-primary-5 blue-hover" @click="loadMore">Other’s Question</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      orders: [
        {
          items: [
          {
      "title": "¿Por qué es necesario implementar la facturación electrónica en lugar de las facturas en papel?",
      "desc": "Mejora la eficiencia y reduce la evasión fiscal al facilitar el intercambio de información entre empresas y la administración tributaria."
    },
          ]
        },
        {
          items: [
          {
      "title": "¿Cuáles son los principales beneficios de la facturación electrónica para los comercios y empresarios?",
      "desc": "Reducción de costos, agilización de procesos, mejora en la gestión del flujo de efectivo y mayor seguridad en las transacciones."
    },
          ]
        },
        {
          items: [
          {
      "title": "¿Cómo la facturación electrónica contribuye a la transparencia y lucha contra la evasión fiscal?",
      "desc": "Proporciona trazabilidad completa de las transacciones, permitiendo a las autoridades tributarias detectar casos de evasión y asegurar el cumplimiento de impuestos."
    },
          ]
        },
        {
          items: [
          {
      "title": "¿Es obligatorio para todos los comercios implementar la facturación electrónica?",
      "desc": "Si, en Colombia es obligatorio y se ha implementado de forma gradual, empezando por empresas más grandes y extendiéndose a otros sectores."
    },
          ]
        },
        {
          items: [
          {
      "title": "¿Qué sucede si una empresa no cumple con la obligación de emitir facturas electrónicas?",
      "desc": "Puede resultar en sanciones y multas por parte de la autoridad tributaria, afectando la reputación y operatividad del negocio."
    }
          ]
        },
       
      ],
      currentPage: 3,
      maxPerPage: 2,
      showReadMore: true
    }
  },
  computed: {
    totalResults () {
      return Object.keys(this.orders).length
    },
    pageCount () {
      return Math.ceil(this.totalResults / this.maxPerPage)
    },
    pageOffest () {
      return this.maxPerPage * this.currentPage
    },
    paginatedOrders () {
      return this.orders.slice(0, this.currentPage * this.maxPerPage)
    }
  },
  methods: {
    loadMore () {
      this.currentPage += 1
    }
  }
}
</script>

<style>
</style>
