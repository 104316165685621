<script setup>
import { useRoute } from "vue-router";
import { useFavicon } from "@vueuse/core";
import { HttpClient } from "../services/http";
import { computed, onMounted, ref } from "vue";

// Components
import FaqSectionTwo from "@/components/FaqSectionTwo.vue";
import RegisterSection from "@/components/RegisterSection.vue";

const route = useRoute();
const http = HttpClient.getInstance();

const faviconURL = ref("");
const logoURL = ref("");

// It is required to use a default company
const companyId = computed(() => 'SOACHA-BAN');

async function getConfig() {
  try {
    const { data } = await http.get(`/company/${companyId.value}`);
    const { config } = data;
    return config;
  } catch (error) {
    console.log("Error al obtener la configuracion");
  }
}

async function setConfig() {
  try {
    const rootElement = document.documentElement;

    const { primary, primaryRGB, favicon, logo } = await getConfig();
    faviconURL.value = favicon;
    logoURL.value = logo;

    rootElement.style.setProperty("--bs-primary", primary);
    rootElement.style.setProperty("--bs-primary-rgb", primaryRGB);
  } catch (error) {
    console.log("Error al cambiar la configuracion");
  }
}

useFavicon(faviconURL);

onMounted(async () => {
  await setConfig();
});
</script>

<template>
  <div class="wrapper">
    <RegisterSection v-bind="{ logoURL }" />
    <FaqSectionTwo />
  </div>
</template>
