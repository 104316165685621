<template>
  <div class="error-404-area">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="error-404-item">
            <div class="error-404-img">
              <div class="scene fill">
                <div class="expand-width" data-depth="0.2">
                  <img src="/images/404/1-631x451.png" alt="404 Image" />
                </div>
              </div>
            </div>
            <div class="error-404-content">
              <h2 class="title text-primary">Por favor vuelva a escanear el QR</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
