export const cities = [
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91263",
    city: "EL ENCANTO",
    zipCode: "913010",
    type: "Urbano",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91405",
    city: "LA CHORRERA",
    zipCode: "914057",
    type: "Rural",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91407",
    city: "LA PEDRERA",
    zipCode: "917010",
    type: "Urbano",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91430",
    city: "LA VICTORIA",
    zipCode: "916017",
    type: "Rural",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91001",
    city: "LETICIA",
    zipCode: "910001",
    type: "Urbano",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91460",
    city: "MIRITI - PARANA",
    zipCode: "916057",
    type: "Rural",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91530",
    city: "PUERTO ALEGRIA",
    zipCode: "913050",
    type: "Urbano",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91536",
    city: "PUERTO ARICA",
    zipCode: "912010",
    type: "Urbano",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91540",
    city: "PUERTO NARI\u00d1O",
    zipCode: "911017",
    type: "Rural",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91669",
    city: "PUERTO SANTANDER",
    zipCode: "915010",
    type: "Urbano",
  },
  {
    departmentDANE: "91",
    department: "AMAZONAS",
    cityDANE: "91798",
    city: "TARAPACA",
    zipCode: "911030",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05002",
    city: "ABEJORRAL",
    zipCode: "055030",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05004",
    city: "ABRIAQUI",
    zipCode: "057460",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05021",
    city: "ALEJANDRIA",
    zipCode: "053820",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05030",
    city: "AMAGA",
    zipCode: "055840",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05031",
    city: "AMALFI",
    zipCode: "052840",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05034",
    city: "ANDES",
    zipCode: "056068",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05036",
    city: "ANGELOPOLIS",
    zipCode: "055830",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05038",
    city: "ANGOSTURA",
    zipCode: "051810",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05040",
    city: "ANORI",
    zipCode: "052857",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05044",
    city: "ANZA",
    zipCode: "056850",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05045",
    city: "APARTADO",
    zipCode: "057841",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05051",
    city: "ARBOLETES",
    zipCode: "057820",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05055",
    city: "ARGELIA",
    zipCode: "054838",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05059",
    city: "ARMENIA",
    zipCode: "055860",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05079",
    city: "BARBOSA",
    zipCode: "051028",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05088",
    city: "BELLO",
    zipCode: "051050",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05086",
    city: "BELMIRA",
    zipCode: "051420",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05091",
    city: "BETANIA",
    zipCode: "056070",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05093",
    city: "BETULIA",
    zipCode: "056860",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05107",
    city: "BRICE\u00d1O",
    zipCode: "052060",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05113",
    city: "BURITICA",
    zipCode: "057030",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05138",
    city: "CA\u00d1ASGORDAS",
    zipCode: "057067",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05120",
    city: "CACERES",
    zipCode: "052450",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05125",
    city: "CAICEDO",
    zipCode: "056840",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05129",
    city: "CALDAS",
    zipCode: "055440",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05134",
    city: "CAMPAMENTO",
    zipCode: "052020",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05142",
    city: "CARACOLI",
    zipCode: "053450",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05145",
    city: "CARAMANTA",
    zipCode: "056040",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05147",
    city: "CAREPA",
    zipCode: "057850",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05150",
    city: "CAROLINA",
    zipCode: "051840",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05154",
    city: "CAUCASIA",
    zipCode: "052410",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05172",
    city: "CHIGORODO",
    zipCode: "057410",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05190",
    city: "CISNEROS",
    zipCode: "053050",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05101",
    city: "CIUDAD BOLIVAR",
    zipCode: "056460",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05197",
    city: "COCORNA",
    zipCode: "054440",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05206",
    city: "CONCEPCION",
    zipCode: "053810",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05209",
    city: "CONCORDIA",
    zipCode: "056410",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05212",
    city: "COPACABANA",
    zipCode: "051047",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05234",
    city: "DABEIBA",
    zipCode: "057430",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05237",
    city: "DON MATIAS",
    zipCode: "051850",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05240",
    city: "EBEJICO",
    zipCode: "055810",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05250",
    city: "EL BAGRE",
    zipCode: "052437",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05148",
    city: "EL CARMEN DE VIBORAL",
    zipCode: "054037",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05697",
    city: "EL SANTUARIO",
    zipCode: "054450",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05264",
    city: "ENTRERRIOS",
    zipCode: "051430",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05266",
    city: "ENVIGADO",
    zipCode: "055428",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05282",
    city: "FREDONIA",
    zipCode: "055070",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05284",
    city: "FRONTINO",
    zipCode: "057450",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05306",
    city: "GIRALDO",
    zipCode: "057040",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05308",
    city: "GIRARDOTA",
    zipCode: "051038",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05310",
    city: "GOMEZ PLATA",
    zipCode: "051830",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05313",
    city: "GRANADA",
    zipCode: "054410",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05315",
    city: "GUADALUPE",
    zipCode: "051820",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05318",
    city: "GUARNE",
    zipCode: "054050",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05321",
    city: "GUATAPE",
    zipCode: "053840",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05347",
    city: "HELICONIA",
    zipCode: "055820",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05353",
    city: "HISPANIA",
    zipCode: "056450",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05360",
    city: "ITAGUI",
    zipCode: "055411",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05361",
    city: "ITUANGO",
    zipCode: "052070",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05364",
    city: "JARDIN",
    zipCode: "056050",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05368",
    city: "JERICO",
    zipCode: "056010",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05376",
    city: "LA CEJA",
    zipCode: "055017",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05380",
    city: "LA ESTRELLA",
    zipCode: "055467",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05390",
    city: "LA PINTADA",
    zipCode: "055060",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05400",
    city: "LA UNION",
    zipCode: "055020",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05411",
    city: "LIBORINA",
    zipCode: "051460",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05425",
    city: "MACEO",
    zipCode: "053460",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05440",
    city: "MARINILLA",
    zipCode: "054020",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05001",
    city: "MEDELLIN",
    zipCode: "050013",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05467",
    city: "MONTEBELLO",
    zipCode: "055040",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05475",
    city: "MURINDO",
    zipCode: "056810",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05480",
    city: "MUTATA",
    zipCode: "057427",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05483",
    city: "NARI\u00d1O",
    zipCode: "054840",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05495",
    city: "NECHI",
    zipCode: "052420",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05490",
    city: "NECOCLI",
    zipCode: "057870",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05501",
    city: "OLAYA",
    zipCode: "051450",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05541",
    city: "PE\u00d1OL",
    zipCode: "053850",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05543",
    city: "PEQUE",
    zipCode: "057010",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05576",
    city: "PUEBLORRICO",
    zipCode: "056440",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05579",
    city: "PUERTO BERRIO",
    zipCode: "053420",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05585",
    city: "PUERTO NARE",
    zipCode: "053430",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05591",
    city: "PUERTO TRIUNFO",
    zipCode: "053440",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05604",
    city: "REMEDIOS",
    zipCode: "052820",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05607",
    city: "RETIRO",
    zipCode: "055438",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05615",
    city: "RIONEGRO",
    zipCode: "054040",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05628",
    city: "SABANALARGA",
    zipCode: "057020",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05631",
    city: "SABANETA",
    zipCode: "055450",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05642",
    city: "SALGAR",
    zipCode: "056478",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05647",
    city: "SAN ANDRES DE CUERQUIA",
    zipCode: "052040",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05649",
    city: "SAN CARLOS",
    zipCode: "054420",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05652",
    city: "SAN FRANCISCO",
    zipCode: "054810",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05656",
    city: "SAN JERONIMO",
    zipCode: "051070",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05658",
    city: "SAN JOSE DE LA MONTA\u00d1A",
    zipCode: "051410",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05659",
    city: "SAN JUAN DE URABA",
    zipCode: "057810",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05660",
    city: "SAN LUIS",
    zipCode: "054430",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05664",
    city: "SAN PEDRO DE LOS MILAGROS",
    zipCode: "051010",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05665",
    city: "SAN PEDRO DE URABA",
    zipCode: "057830",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05667",
    city: "SAN RAFAEL",
    zipCode: "053838",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05670",
    city: "SAN ROQUE",
    zipCode: "053030",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05674",
    city: "SAN VICENTE",
    zipCode: "054010",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05679",
    city: "SANTA BARBARA",
    zipCode: "055050",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05686",
    city: "SANTA ROSA DE OSOS",
    zipCode: "051860",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05042",
    city: "SANTAFE DE ANTIOQUIA",
    zipCode: "057050",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05690",
    city: "SANTO DOMINGO",
    zipCode: "053040",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05736",
    city: "SEGOVIA",
    zipCode: "052810",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05756",
    city: "SONSON",
    zipCode: "054820",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05761",
    city: "SOPETRAN",
    zipCode: "051440",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05789",
    city: "TAMESIS",
    zipCode: "056020",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05790",
    city: "TARAZA",
    zipCode: "052460",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05792",
    city: "TARSO",
    zipCode: "056430",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05809",
    city: "TITIRIBI",
    zipCode: "055858",
    type: "Rural",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05819",
    city: "TOLEDO",
    zipCode: "052050",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05837",
    city: "TURBO",
    zipCode: "057860",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05842",
    city: "URAMITA",
    zipCode: "057440",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05847",
    city: "URRAO",
    zipCode: "056830",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05854",
    city: "VALDIVIA",
    zipCode: "052010",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05856",
    city: "VALPARAISO",
    zipCode: "056030",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05858",
    city: "VEGACHI",
    zipCode: "052830",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05861",
    city: "VENECIA",
    zipCode: "056420",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05873",
    city: "VIGIA DEL FUERTE",
    zipCode: "056820",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05885",
    city: "YALI",
    zipCode: "053010",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05887",
    city: "YARUMAL",
    zipCode: "052030",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05890",
    city: "YOLOMBO",
    zipCode: "053020",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05893",
    city: "YONDO",
    zipCode: "053410",
    type: "Urbano",
  },
  {
    departmentDANE: "05",
    department: "ANTIOQUIA",
    cityDANE: "05895",
    city: "ZARAGOZA",
    zipCode: "052440",
    type: "Urbano",
  },
  {
    departmentDANE: "81",
    department: "ARAUCA",
    cityDANE: "81001",
    city: "ARAUCA",
    zipCode: "810001",
    type: "Urbano",
  },
  {
    departmentDANE: "81",
    department: "ARAUCA",
    cityDANE: "81065",
    city: "ARAUQUITA",
    zipCode: "816010",
    type: "Urbano",
  },
  {
    departmentDANE: "81",
    department: "ARAUCA",
    cityDANE: "81220",
    city: "CRAVO NORTE",
    zipCode: "812010",
    type: "Urbano",
  },
  {
    departmentDANE: "81",
    department: "ARAUCA",
    cityDANE: "81300",
    city: "FORTUL",
    zipCode: "814050",
    type: "Urbano",
  },
  {
    departmentDANE: "81",
    department: "ARAUCA",
    cityDANE: "81591",
    city: "PUERTO RONDON",
    zipCode: "813010",
    type: "Urbano",
  },
  {
    departmentDANE: "81",
    department: "ARAUCA",
    cityDANE: "81736",
    city: "SARAVENA",
    zipCode: "815010",
    type: "Urbano",
  },
  {
    departmentDANE: "81",
    department: "ARAUCA",
    cityDANE: "81794",
    city: "TAME",
    zipCode: "814018",
    type: "Rural",
  },
  {
    departmentDANE: "88",
    department: "ARCHIPIELAGO DE SAN ANDRES",
    cityDANE: "88564",
    city: "PROVIDENCIA",
    zipCode: "880027",
    type: "Rural",
  },
  {
    departmentDANE: "88",
    department: "ARCHIPIELAGO DE SAN ANDRES",
    cityDANE: "88001",
    city: "SAN ANDRES",
    zipCode: "880008",
    type: "Rural",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08078",
    city: "BARANOA",
    zipCode: "082027",
    type: "Rural",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08001",
    city: "BARRANQUILLA",
    zipCode: "080010",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08137",
    city: "CAMPO DE LA CRUZ",
    zipCode: "084040",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08141",
    city: "CANDELARIA",
    zipCode: "084020",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08296",
    city: "GALAPA",
    zipCode: "082001",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08372",
    city: "JUAN DE ACOSTA",
    zipCode: "081048",
    type: "Rural",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08421",
    city: "LURUACO",
    zipCode: "085060",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08433",
    city: "MALAMBO",
    zipCode: "083027",
    type: "Rural",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08436",
    city: "MANATI",
    zipCode: "085020",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08520",
    city: "PALMAR DE VARELA",
    zipCode: "083087",
    type: "Rural",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08549",
    city: "PIOJO",
    zipCode: "081060",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08558",
    city: "POLONUEVO",
    zipCode: "082040",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08560",
    city: "PONEDERA",
    zipCode: "084001",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08573",
    city: "PUERTO COLOMBIA",
    zipCode: "081008",
    type: "Rural",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08606",
    city: "REPELON",
    zipCode: "085040",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08634",
    city: "SABANAGRANDE",
    zipCode: "083040",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08675",
    city: "SANTA LUCIA",
    zipCode: "084080",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08685",
    city: "SANTO TOMAS",
    zipCode: "083067",
    type: "Rural",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08758",
    city: "SOLEDAD",
    zipCode: "083007",
    type: "Rural",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08770",
    city: "SUAN",
    zipCode: "084060",
    type: "Urbano",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08832",
    city: "TUBARA",
    zipCode: "081027",
    type: "Rural",
  },
  {
    departmentDANE: "8",
    department: "ATLANTICO",
    cityDANE: "08849",
    city: "USIACURI",
    zipCode: "082067",
    type: "Rural",
  },
  {
    departmentDANE: "11",
    department: "BOGOTA",
    cityDANE: "11001",
    city: "BOGOTA, D.C.",
    zipCode: "111511",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13006",
    city: "ACHI",
    zipCode: "134020",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13030",
    city: "ALTOS DEL ROSARIO",
    zipCode: "133508",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13042",
    city: "ARENAL",
    zipCode: "134520",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13052",
    city: "ARJONA",
    zipCode: "131028",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13062",
    city: "ARROYOHONDO",
    zipCode: "131560",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13074",
    city: "BARRANCO DE LOBA",
    zipCode: "133517",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13140",
    city: "CALAMAR",
    zipCode: "131547",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13160",
    city: "CANTAGALLO",
    zipCode: "135060",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13001",
    city: "CARTAGENA",
    zipCode: "130019",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13188",
    city: "CICUCO",
    zipCode: "132550",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13222",
    city: "CLEMENCIA",
    zipCode: "130510",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13212",
    city: "CORDOBA",
    zipCode: "132507",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13244",
    city: "EL CARMEN DE BOLIVAR",
    zipCode: "132058",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13248",
    city: "EL GUAMO",
    zipCode: "132007",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13268",
    city: "EL PE\u00d1ON",
    zipCode: "133550",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13300",
    city: "HATILLO DE LOBA",
    zipCode: "133040",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13430",
    city: "MAGANGUE",
    zipCode: "132518",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13433",
    city: "MAHATES",
    zipCode: "131048",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13440",
    city: "MARGARITA",
    zipCode: "133020",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13442",
    city: "MARIA LA BAJA",
    zipCode: "131060",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13468",
    city: "MOMPOS",
    zipCode: "132560",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13458",
    city: "MONTECRISTO",
    zipCode: "134070",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13473",
    city: "MORALES",
    zipCode: "134540",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13490",
    city: "NOROSI",
    zipCode: "134510",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13549",
    city: "PINILLOS",
    zipCode: "134001",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13580",
    city: "REGIDOR",
    zipCode: "133560",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13600",
    city: "RIO VIEJO",
    zipCode: "134501",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13620",
    city: "SAN CRISTOBAL",
    zipCode: "131520",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13647",
    city: "SAN ESTANISLAO",
    zipCode: "130540",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13650",
    city: "SAN FERNANDO",
    zipCode: "133007",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13654",
    city: "SAN JACINTO",
    zipCode: "132030",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13655",
    city: "SAN JACINTO DEL CAUCA",
    zipCode: "134060",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13657",
    city: "SAN JUAN NEPOMUCENO",
    zipCode: "132010",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13667",
    city: "SAN MARTIN DE LOBA",
    zipCode: "133530",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13670",
    city: "SAN PABLO",
    zipCode: "135040",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13673",
    city: "SANTA CATALINA",
    zipCode: "130501",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13683",
    city: "SANTA ROSA",
    zipCode: "130527",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13688",
    city: "SANTA ROSA DEL SUR",
    zipCode: "135001",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13744",
    city: "SIMITI",
    zipCode: "135020",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13760",
    city: "SOPLAVIENTO",
    zipCode: "131501",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13780",
    city: "TALAIGUA NUEVO",
    zipCode: "132540",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13810",
    city: "TIQUISIO",
    zipCode: "134040",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13836",
    city: "TURBACO",
    zipCode: "131007",
    type: "Rural",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13838",
    city: "TURBANA",
    zipCode: "131010",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13873",
    city: "VILLANUEVA",
    zipCode: "130530",
    type: "Urbano",
  },
  {
    departmentDANE: "13",
    department: "BOLIVAR",
    cityDANE: "13894",
    city: "ZAMBRANO",
    zipCode: "132047",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15022",
    city: "ALMEIDA",
    zipCode: "153020",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15047",
    city: "AQUITANIA",
    zipCode: "152420",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15051",
    city: "ARCABUCO",
    zipCode: "154201",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15087",
    city: "BELEN",
    zipCode: "150640",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15090",
    city: "BERBEO",
    zipCode: "152617",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15092",
    city: "BETEITIVA",
    zipCode: "150610",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15097",
    city: "BOAVITA",
    zipCode: "151060",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15104",
    city: "BOYACA",
    zipCode: "153610",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15109",
    city: "BUENAVISTA",
    zipCode: "154840",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15114",
    city: "BUSBANZA",
    zipCode: "152087",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15135",
    city: "CAMPOHERMOSO",
    zipCode: "152640",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15162",
    city: "CERINZA",
    zipCode: "150627",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15172",
    city: "CHINAVITA",
    zipCode: "153287",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15176",
    city: "CHIQUINQUIRA",
    zipCode: "154640",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15232",
    city: "CHIQUIZA",
    zipCode: "154020",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15180",
    city: "CHISCAS",
    zipCode: "151401",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15183",
    city: "CHITA",
    zipCode: "151601",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15185",
    city: "CHITARAQUE",
    zipCode: "154420",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15187",
    city: "CHIVATA",
    zipCode: "150240",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15236",
    city: "CHIVOR",
    zipCode: "153001",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15189",
    city: "CIENEGA",
    zipCode: "153440",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15204",
    city: "COMBITA",
    zipCode: "150201",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15212",
    city: "COPER",
    zipCode: "154860",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15215",
    city: "CORRALES",
    zipCode: "152060",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15218",
    city: "COVARACHIA",
    zipCode: "151040",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15223",
    city: "CUBARA",
    zipCode: "151420",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15224",
    city: "CUCAITA",
    zipCode: "154060",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15226",
    city: "CUITIVA",
    zipCode: "152230",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15238",
    city: "DUITAMA",
    zipCode: "150467",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15244",
    city: "EL COCUY",
    zipCode: "151280",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15248",
    city: "EL ESPINO",
    zipCode: "151240",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15272",
    city: "FIRAVITOBA",
    zipCode: "152250",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15276",
    city: "FLORESTA",
    zipCode: "150601",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15293",
    city: "GACHANTIVA",
    zipCode: "154220",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15296",
    city: "GAMEZA",
    zipCode: "152020",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15332",
    city: "G\u00c3\u0153ICAN",
    zipCode: "151440",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15299",
    city: "GARAGOA",
    zipCode: "152860",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15317",
    city: "GUACAMAYAS",
    zipCode: "151220",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15322",
    city: "GUATEQUE",
    zipCode: "153050",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15325",
    city: "GUAYATA",
    zipCode: "153040",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15362",
    city: "IZA",
    zipCode: "152240",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15367",
    city: "JENESANO",
    zipCode: "153601",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15380",
    city: "LA CAPILLA",
    zipCode: "153220",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15403",
    city: "LA UVITA",
    zipCode: "150860",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15377",
    city: "LABRANZAGRANDE",
    zipCode: "151840",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15425",
    city: "MACANAL",
    zipCode: "152840",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15442",
    city: "MARIPI",
    zipCode: "154820",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15455",
    city: "MIRAFLORES",
    zipCode: "152667",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15464",
    city: "MONGUA",
    zipCode: "152001",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15466",
    city: "MONGUI",
    zipCode: "152201",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15469",
    city: "MONIQUIRA",
    zipCode: "154260",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15476",
    city: "MOTAVITA",
    zipCode: "154080",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15480",
    city: "MUZO",
    zipCode: "154880",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15491",
    city: "NOBSA",
    zipCode: "152280",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15494",
    city: "NUEVO COLON",
    zipCode: "153620",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15500",
    city: "OICATA",
    zipCode: "150220",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15507",
    city: "OTANCHE",
    zipCode: "155060",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15511",
    city: "PACHAVITA",
    zipCode: "153210",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15514",
    city: "PAEZ",
    zipCode: "152620",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15516",
    city: "PAIPA",
    zipCode: "150447",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15518",
    city: "PAJARITO",
    zipCode: "152407",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15522",
    city: "PANQUEBA",
    zipCode: "151260",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15531",
    city: "PAUNA",
    zipCode: "154801",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15533",
    city: "PAYA",
    zipCode: "151827",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15537",
    city: "PAZ DE RIO",
    zipCode: "150680",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15542",
    city: "PESCA",
    zipCode: "152460",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15550",
    city: "PISBA",
    zipCode: "151801",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15572",
    city: "PUERTO BOYACA",
    zipCode: "155208",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15580",
    city: "QUIPAMA",
    zipCode: "155027",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15599",
    city: "RAMIRIQUI",
    zipCode: "153407",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15600",
    city: "RAQUIRA",
    zipCode: "153801",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15621",
    city: "RONDON",
    zipCode: "153420",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15632",
    city: "SABOYA",
    zipCode: "154601",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15638",
    city: "SACHICA",
    zipCode: "153887",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15646",
    city: "SAMACA",
    zipCode: "153660",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15660",
    city: "SAN EDUARDO",
    zipCode: "152601",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15664",
    city: "SAN JOSE DE PARE",
    zipCode: "154460",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15667",
    city: "SAN LUIS DE GACENO",
    zipCode: "152801",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15673",
    city: "SAN MATEO",
    zipCode: "151207",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15676",
    city: "SAN MIGUEL DE SEMA",
    zipCode: "153820",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15681",
    city: "SAN PABLO DE BORBUR",
    zipCode: "155040",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15690",
    city: "SANTA MARIA",
    zipCode: "152820",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15693",
    city: "SANTA ROSA DE VITERBO",
    zipCode: "150480",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15696",
    city: "SANTA SOFIA",
    zipCode: "154247",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15686",
    city: "SANTANA",
    zipCode: "154440",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15720",
    city: "SATIVANORTE",
    zipCode: "150820",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15723",
    city: "SATIVASUR",
    zipCode: "150801",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15740",
    city: "SIACHOQUE",
    zipCode: "153460",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15753",
    city: "SOATA",
    zipCode: "151001",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15757",
    city: "SOCHA",
    zipCode: "151640",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15755",
    city: "SOCOTA",
    zipCode: "151620",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15759",
    city: "SOGAMOSO",
    zipCode: "152217",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15761",
    city: "SOMONDOCO",
    zipCode: "153030",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15762",
    city: "SORA",
    zipCode: "154040",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15764",
    city: "SORACA",
    zipCode: "153480",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15763",
    city: "SOTAQUIRA",
    zipCode: "150420",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15774",
    city: "SUSACON",
    zipCode: "150880",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15776",
    city: "SUTAMARCHAN",
    zipCode: "153867",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15778",
    city: "SUTATENZA",
    zipCode: "153067",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15790",
    city: "TASCO",
    zipCode: "151660",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15798",
    city: "TENZA",
    zipCode: "153207",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15804",
    city: "TIBANA",
    zipCode: "153260",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15806",
    city: "TIBASOSA",
    zipCode: "152260",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15808",
    city: "TINJACA",
    zipCode: "153840",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15810",
    city: "TIPACOQUE",
    zipCode: "151020",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15814",
    city: "TOCA",
    zipCode: "150260",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15816",
    city: "TOG\u00c3\u0153I",
    zipCode: "154401",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15820",
    city: "TOPAGA",
    zipCode: "152047",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15822",
    city: "TOTA",
    zipCode: "152440",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15001",
    city: "TUNJA",
    zipCode: "150003",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15832",
    city: "TUNUNGUA",
    zipCode: "154687",
    type: "Rural",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15835",
    city: "TURMEQUE",
    zipCode: "153630",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15837",
    city: "TUTA",
    zipCode: "150401",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15839",
    city: "TUTAZA",
    zipCode: "150660",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15842",
    city: "UMBITA",
    zipCode: "153240",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15861",
    city: "VENTAQUEMADA",
    zipCode: "153640",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15407",
    city: "VILLA DE LEYVA",
    zipCode: "154001",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15879",
    city: "VIRACACHA",
    zipCode: "153450",
    type: "Urbano",
  },
  {
    departmentDANE: "15",
    department: "BOYACA",
    cityDANE: "15897",
    city: "ZETAQUIRA",
    zipCode: "152680",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17013",
    city: "AGUADAS",
    zipCode: "172020",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17042",
    city: "ANSERMA",
    zipCode: "177080",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17050",
    city: "ARANZAZU",
    zipCode: "171040",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17088",
    city: "BELALCAZAR",
    zipCode: "177001",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17174",
    city: "CHINCHINA",
    zipCode: "176020",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17272",
    city: "FILADELFIA",
    zipCode: "171020",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17380",
    city: "LA DORADA",
    zipCode: "175038",
    type: "Rural",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17388",
    city: "LA MERCED",
    zipCode: "172067",
    type: "Rural",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17001",
    city: "MANIZALES",
    zipCode: "170007",
    type: "Rural",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17433",
    city: "MANZANARES",
    zipCode: "173020",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17442",
    city: "MARMATO",
    zipCode: "178007",
    type: "Rural",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17444",
    city: "MARQUETALIA",
    zipCode: "173040",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17446",
    city: "MARULANDA",
    zipCode: "173007",
    type: "Rural",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17486",
    city: "NEIRA",
    zipCode: "171001",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17495",
    city: "NORCASIA",
    zipCode: "175001",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17513",
    city: "PACORA",
    zipCode: "172040",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17524",
    city: "PALESTINA",
    zipCode: "176040",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17541",
    city: "PENSILVANIA",
    zipCode: "173060",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17614",
    city: "RIOSUCIO",
    zipCode: "178057",
    type: "Rural",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17616",
    city: "RISARALDA",
    zipCode: "177060",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17653",
    city: "SALAMINA",
    zipCode: "172001",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17662",
    city: "SAMANA",
    zipCode: "174001",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17665",
    city: "SAN JOSE",
    zipCode: "177040",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17777",
    city: "SUPIA",
    zipCode: "178020",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17867",
    city: "VICTORIA",
    zipCode: "174030",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17873",
    city: "VILLAMARIA",
    zipCode: "176001",
    type: "Urbano",
  },
  {
    departmentDANE: "17",
    department: "CALDAS",
    cityDANE: "17877",
    city: "VITERBO",
    zipCode: "177020",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18029",
    city: "ALBANIA",
    zipCode: "186030",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18094",
    city: "BELEN DE LOS ANDAQUIES",
    zipCode: "186010",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18150",
    city: "CARTAGENA DEL CHAIRA",
    zipCode: "183010",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18205",
    city: "CURILLO",
    zipCode: "186050",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18247",
    city: "EL DONCELLO",
    zipCode: "181010",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18256",
    city: "EL PAUJIL",
    zipCode: "181030",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18001",
    city: "FLORENCIA",
    zipCode: "180009",
    type: "Rural",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18410",
    city: "LA MONTA\u00d1ITA",
    zipCode: "181059",
    type: "Rural",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18460",
    city: "MILAN",
    zipCode: "185030",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18479",
    city: "MORELIA",
    zipCode: "185010",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18592",
    city: "PUERTO RICO",
    zipCode: "182050",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18610",
    city: "SAN JOSE DEL FRAGUA",
    zipCode: "186070",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18753",
    city: "SAN VICENTE DEL CAGUAN",
    zipCode: "182010",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18756",
    city: "SOLANO",
    zipCode: "184010",
    type: "Urbano",
  },
  {
    departmentDANE: "18",
    department: "CAQUETA",
    cityDANE: "18785",
    city: "SOLITA",
    zipCode: "185070",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85010",
    city: "AGUAZUL",
    zipCode: "856010",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85015",
    city: "CHAMEZA",
    zipCode: "856030",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85125",
    city: "HATO COROZAL",
    zipCode: "852010",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85136",
    city: "LA SALINA",
    zipCode: "851010",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85139",
    city: "MANI",
    zipCode: "854018",
    type: "Rural",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85162",
    city: "MONTERREY",
    zipCode: "855010",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85225",
    city: "NUNCHIA",
    zipCode: "851070",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85230",
    city: "OROCUE",
    zipCode: "853050",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85250",
    city: "PAZ DE ARIPORO",
    zipCode: "852030",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85263",
    city: "PORE",
    zipCode: "852057",
    type: "Rural",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85279",
    city: "RECETOR",
    zipCode: "856050",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85315",
    city: "SACAMA",
    zipCode: "851038",
    type: "Rural",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85325",
    city: "SAN LUIS DE PALENQUE",
    zipCode: "853030",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85400",
    city: "TAMARA",
    zipCode: "851050",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85410",
    city: "TAURAMENA",
    zipCode: "854030",
    type: "Urbano",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85430",
    city: "TRINIDAD",
    zipCode: "853019",
    type: "Rural",
  },
  {
    departmentDANE: "85",
    department: "CASANARE",
    cityDANE: "85001",
    city: "YOPAL",
    zipCode: "850009",
    type: "Rural",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19022",
    city: "ALMAGUER",
    zipCode: "194080",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19075",
    city: "BALBOA",
    zipCode: "195530",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19100",
    city: "BOLIVAR",
    zipCode: "195001",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19110",
    city: "BUENOS AIRES",
    zipCode: "191001",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19130",
    city: "CAJIBIO",
    zipCode: "190501",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19137",
    city: "CALDONO",
    zipCode: "192040",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19142",
    city: "CALOTO",
    zipCode: "191070",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19212",
    city: "CORINTO",
    zipCode: "191560",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19256",
    city: "EL TAMBO",
    zipCode: "193570",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19318",
    city: "GUAPI",
    zipCode: "196001",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19355",
    city: "INZA",
    zipCode: "192548",
    type: "Rural",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19364",
    city: "JAMBALO",
    zipCode: "192029",
    type: "Rural",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19392",
    city: "LA SIERRA",
    zipCode: "194001",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19397",
    city: "LA VEGA",
    zipCode: "194020",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19418",
    city: "LOPEZ",
    zipCode: "196060",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19450",
    city: "MERCADERES",
    zipCode: "195060",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19455",
    city: "MIRANDA",
    zipCode: "191520",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19513",
    city: "PADILLA",
    zipCode: "191540",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19532",
    city: "PATIA",
    zipCode: "195501",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19533",
    city: "PIAMONTE",
    zipCode: "194550",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19548",
    city: "PIENDAMO",
    zipCode: "190530",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19001",
    city: "POPAYAN",
    zipCode: "190001",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19573",
    city: "PUERTO TEJADA",
    zipCode: "191501",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19585",
    city: "PURACE",
    zipCode: "193001",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19622",
    city: "ROSAS",
    zipCode: "193550",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19693",
    city: "SAN SEBASTIAN",
    zipCode: "194501",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19698",
    city: "SANTANDER DE QUILICHAO",
    zipCode: "191030",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19743",
    city: "SILVIA",
    zipCode: "192070",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19760",
    city: "SOTARA",
    zipCode: "193501",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19780",
    city: "SUAREZ",
    zipCode: "190580",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19785",
    city: "SUCRE",
    zipCode: "194060",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19807",
    city: "TIMBIO",
    zipCode: "193520",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19809",
    city: "TIMBIQUI",
    zipCode: "196030",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19821",
    city: "TORIBIO",
    zipCode: "192001",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19824",
    city: "TOTORO",
    zipCode: "192570",
    type: "Urbano",
  },
  {
    departmentDANE: "19",
    department: "CAUCA",
    cityDANE: "19845",
    city: "VILLA RICA",
    zipCode: "191060",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20011",
    city: "AGUACHICA",
    zipCode: "205010",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20013",
    city: "AGUSTIN CODAZZI",
    zipCode: "202050",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20032",
    city: "ASTREA",
    zipCode: "201040",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20045",
    city: "BECERRIL",
    zipCode: "203001",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20060",
    city: "BOSCONIA",
    zipCode: "201027",
    type: "Rural",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20175",
    city: "CHIMICHAGUA",
    zipCode: "201050",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20178",
    city: "CHIRIGUANA",
    zipCode: "203040",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20228",
    city: "CURUMANI",
    zipCode: "203060",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20238",
    city: "EL COPEY",
    zipCode: "201010",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20250",
    city: "EL PASO",
    zipCode: "201030",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20295",
    city: "GAMARRA",
    zipCode: "205001",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20310",
    city: "GONZALEZ",
    zipCode: "205030",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20383",
    city: "LA GLORIA",
    zipCode: "204060",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20400",
    city: "LA JAGUA DE IBIRICO",
    zipCode: "203020",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20621",
    city: "LA PAZ",
    zipCode: "202010",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20443",
    city: "MANAURE",
    zipCode: "202001",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20517",
    city: "PAILITAS",
    zipCode: "204001",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20550",
    city: "PELAYA",
    zipCode: "204047",
    type: "Rural",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20570",
    city: "PUEBLO BELLO",
    zipCode: "201001",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20614",
    city: "RIO DE ORO",
    zipCode: "205040",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20710",
    city: "SAN ALBERTO",
    zipCode: "205070",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20750",
    city: "SAN DIEGO",
    zipCode: "202030",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20770",
    city: "SAN MARTIN",
    zipCode: "205050",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20787",
    city: "TAMALAMEQUE",
    zipCode: "204020",
    type: "Urbano",
  },
  {
    departmentDANE: "20",
    department: "CESAR",
    cityDANE: "20001",
    city: "VALLEDUPAR",
    zipCode: "200018",
    type: "Rural",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27006",
    city: "ACANDI",
    zipCode: "278010",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27025",
    city: "ALTO BAUDO",
    zipCode: "276070",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27050",
    city: "ATRATO",
    zipCode: "272010",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27073",
    city: "BAGADO",
    zipCode: "271050",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27075",
    city: "BAHIA SOLANO",
    zipCode: "276030",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27077",
    city: "BAJO BAUDO",
    zipCode: "275030",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27099",
    city: "BOJAYA",
    zipCode: "277050",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27150",
    city: "CARMEN DEL DARIEN",
    zipCode: "277030",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27160",
    city: "CERTEGUI",
    zipCode: "272020",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27205",
    city: "CONDOTO",
    zipCode: "273030",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27135",
    city: "EL CANTON DEL SAN PABLO",
    zipCode: "272040",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27245",
    city: "EL CARMEN DE ATRATO",
    zipCode: "271010",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27250",
    city: "EL LITORAL DEL SAN JUAN",
    zipCode: "275050",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27361",
    city: "ISTMINA",
    zipCode: "274010",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27372",
    city: "JURADO",
    zipCode: "276010",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27413",
    city: "LLORO",
    zipCode: "271030",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27425",
    city: "MEDIO ATRATO",
    zipCode: "270070",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27430",
    city: "MEDIO BAUDO",
    zipCode: "275010",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27450",
    city: "MEDIO SAN JUAN",
    zipCode: "274030",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27491",
    city: "NOVITA",
    zipCode: "273050",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27495",
    city: "NUQUI",
    zipCode: "276050",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27001",
    city: "QUIBDO",
    zipCode: "270002",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27580",
    city: "RIO IRO",
    zipCode: "273010",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27600",
    city: "RIO QUITO",
    zipCode: "272050",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27660",
    city: "SAN JOSE DEL PALMAR",
    zipCode: "273070",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27745",
    city: "SIPI",
    zipCode: "274050",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27787",
    city: "TADO",
    zipCode: "271070",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27800",
    city: "UNGUIA",
    zipCode: "278030",
    type: "Urbano",
  },
  {
    departmentDANE: "27",
    department: "CHOCO",
    cityDANE: "27810",
    city: "UNION PANAMERICANA",
    zipCode: "272030",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23068",
    city: "AYAPEL",
    zipCode: "233530",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23090",
    city: "CANALETE",
    zipCode: "235040",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23162",
    city: "CERETE",
    zipCode: "230550",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23168",
    city: "CHIMA",
    zipCode: "232010",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23182",
    city: "CHINU",
    zipCode: "232050",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23189",
    city: "CIENAGA DE ORO",
    zipCode: "232520",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23300",
    city: "COTORRA",
    zipCode: "230501",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23350",
    city: "LA APARTADA",
    zipCode: "233507",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23417",
    city: "LORICA",
    zipCode: "231029",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23419",
    city: "LOS CORDOBAS",
    zipCode: "235020",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23500",
    city: "MO\u00d1ITOS",
    zipCode: "231007",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23464",
    city: "MOMIL",
    zipCode: "232008",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23466",
    city: "MONTELIBANO",
    zipCode: "234007",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23001",
    city: "MONTERIA",
    zipCode: "230017",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23555",
    city: "PLANETA RICA",
    zipCode: "233040",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23570",
    city: "PUEBLO NUEVO",
    zipCode: "233001",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23574",
    city: "PUERTO ESCONDIDO",
    zipCode: "235001",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23580",
    city: "PUERTO LIBERTADOR",
    zipCode: "234038",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23586",
    city: "PURISIMA",
    zipCode: "231540",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23660",
    city: "SAHAGUN",
    zipCode: "232549",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23670",
    city: "SAN ANDRES SOTAVENTO",
    zipCode: "232030",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23672",
    city: "SAN ANTERO",
    zipCode: "231520",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23675",
    city: "SAN BERNARDO DEL VIENTO",
    zipCode: "231501",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23682",
    city: "SAN JOSE DE URE",
    zipCode: "234010",
    type: "Urbano",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23686",
    city: "SAN PELAYO",
    zipCode: "230538",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23807",
    city: "TIERRALTA",
    zipCode: "234517",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23815",
    city: "TUCHIN",
    zipCode: "232027",
    type: "Rural",
  },
  {
    departmentDANE: "23",
    department: "CORDOBA",
    cityDANE: "23855",
    city: "VALENCIA",
    zipCode: "234539",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25001",
    city: "AGUA DE DIOS",
    zipCode: "252850",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25019",
    city: "ALBAN",
    zipCode: "253207",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25035",
    city: "ANAPOIMA",
    zipCode: "252647",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25040",
    city: "ANOLAIMA",
    zipCode: "253048",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25599",
    city: "APULO",
    zipCode: "252650",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25053",
    city: "ARBELAEZ",
    zipCode: "252001",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25086",
    city: "BELTRAN",
    zipCode: "253260",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25095",
    city: "BITUIMA",
    zipCode: "253220",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25099",
    city: "BOJACA",
    zipCode: "253001",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25120",
    city: "CABRERA",
    zipCode: "252040",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25123",
    city: "CACHIPAY",
    zipCode: "253020",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25126",
    city: "CAJICA",
    zipCode: "250240",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25148",
    city: "CAPARRAPI",
    zipCode: "253460",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25151",
    city: "CAQUEZA",
    zipCode: "251827",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25154",
    city: "CARMEN DE CARUPA",
    zipCode: "250420",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25168",
    city: "CHAGUANI",
    zipCode: "253240",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25175",
    city: "CHIA",
    zipCode: "250001",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25178",
    city: "CHIPAQUE",
    zipCode: "251801",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25181",
    city: "CHOACHI",
    zipCode: "251620",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25183",
    city: "CHOCONTA",
    zipCode: "250801",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25200",
    city: "COGUA",
    zipCode: "250408",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25214",
    city: "COTA",
    zipCode: "250010",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25224",
    city: "CUCUNUBA",
    zipCode: "250450",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25245",
    city: "EL COLEGIO",
    zipCode: "252630",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25260",
    city: "EL ROSAL",
    zipCode: "250210",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25269",
    city: "FACATATIVA",
    zipCode: "253058",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25279",
    city: "FOMEQUE",
    zipCode: "251640",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25281",
    city: "FOSCA",
    zipCode: "251830",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25286",
    city: "FUNZA",
    zipCode: "250020",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25288",
    city: "FUQUENE",
    zipCode: "250620",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25290",
    city: "FUSAGASUGA",
    zipCode: "252219",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25293",
    city: "GACHALA",
    zipCode: "251250",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25295",
    city: "GACHANCIPA",
    zipCode: "251020",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25297",
    city: "GACHETA",
    zipCode: "251230",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25299",
    city: "GAMA",
    zipCode: "251240",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25307",
    city: "GIRARDOT",
    zipCode: "252431",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25317",
    city: "GUACHETA",
    zipCode: "250610",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25320",
    city: "GUADUAS",
    zipCode: "253448",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25322",
    city: "GUASCA",
    zipCode: "251210",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25324",
    city: "GUATAQUI",
    zipCode: "252820",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25326",
    city: "GUATAVITA",
    zipCode: "251060",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25328",
    city: "GUAYABAL DE SIQUIMA",
    zipCode: "253210",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25335",
    city: "GUAYABETAL",
    zipCode: "251850",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25339",
    city: "GUTIERREZ",
    zipCode: "251860",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25368",
    city: "JERUSALEN",
    zipCode: "252810",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25372",
    city: "JUNIN",
    zipCode: "251220",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25377",
    city: "LA CALERA",
    zipCode: "251201",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25386",
    city: "LA MESA",
    zipCode: "252601",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25394",
    city: "LA PALMA",
    zipCode: "253808",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25398",
    city: "LA PE\u00d1A",
    zipCode: "253640",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25407",
    city: "LENGUAZAQUE",
    zipCode: "250601",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25426",
    city: "MACHETA",
    zipCode: "250840",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25430",
    city: "MADRID",
    zipCode: "250038",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25436",
    city: "MANTA",
    zipCode: "250830",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25438",
    city: "MEDINA",
    zipCode: "251420",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25473",
    city: "MOSQUERA",
    zipCode: "250040",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25486",
    city: "NEMOCON",
    zipCode: "251030",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25488",
    city: "NILO",
    zipCode: "252401",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25489",
    city: "NIMAIMA",
    zipCode: "253630",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25491",
    city: "NOCAIMA",
    zipCode: "253620",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25513",
    city: "PACHO",
    zipCode: "254001",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25518",
    city: "PAIME",
    zipCode: "254040",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25524",
    city: "PANDI",
    zipCode: "252010",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25530",
    city: "PARATEBUENO",
    zipCode: "251401",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25535",
    city: "PASCA",
    zipCode: "252201",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25572",
    city: "PUERTO SALGAR",
    zipCode: "253480",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25580",
    city: "PULI",
    zipCode: "252801",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25592",
    city: "QUEBRADANEGRA",
    zipCode: "253427",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25594",
    city: "QUETAME",
    zipCode: "251840",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25596",
    city: "QUIPILE",
    zipCode: "253030",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25612",
    city: "RICAURTE",
    zipCode: "252417",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25645",
    city: "SAN ANTONIO DEL TEQUENDAMA",
    zipCode: "252620",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25649",
    city: "SAN BERNARDO",
    zipCode: "252020",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25653",
    city: "SAN CAYETANO",
    zipCode: "254050",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25662",
    city: "SAN JUAN DE RIO SECO",
    zipCode: "253250",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25718",
    city: "SASAIMA",
    zipCode: "253401",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25736",
    city: "SESQUILE",
    zipCode: "251050",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25740",
    city: "SIBATE",
    zipCode: "250077",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25743",
    city: "SILVANIA",
    zipCode: "252240",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25745",
    city: "SIMIJACA",
    zipCode: "250647",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25754",
    city: "SOACHA",
    zipCode: "250051",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25758",
    city: "SOPO",
    zipCode: "251001",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25769",
    city: "SUBACHOQUE",
    zipCode: "250228",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25772",
    city: "SUESCA",
    zipCode: "251040",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25777",
    city: "SUPATA",
    zipCode: "253660",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25779",
    city: "SUSA",
    zipCode: "250630",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25781",
    city: "SUTATAUSA",
    zipCode: "250440",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25785",
    city: "TABIO",
    zipCode: "250237",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25793",
    city: "TAUSA",
    zipCode: "250410",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25797",
    city: "TENA",
    zipCode: "252610",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25799",
    city: "TENJO",
    zipCode: "250201",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25805",
    city: "TIBACUY",
    zipCode: "252230",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25807",
    city: "TIBIRITA",
    zipCode: "250820",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25815",
    city: "TOCAIMA",
    zipCode: "252840",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25817",
    city: "TOCANCIPA",
    zipCode: "251010",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25823",
    city: "TOPAIPI",
    zipCode: "253820",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25839",
    city: "UBALA",
    zipCode: "251260",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25841",
    city: "UBAQUE",
    zipCode: "251601",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25845",
    city: "UNE",
    zipCode: "251810",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25851",
    city: "UTICA",
    zipCode: "253430",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25862",
    city: "VERGARA",
    zipCode: "253650",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25867",
    city: "VIANI",
    zipCode: "253230",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25843",
    city: "VILLA DE SAN DIEGO DE UBATE",
    zipCode: "250430",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25871",
    city: "VILLAGOMEZ",
    zipCode: "254030",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25873",
    city: "VILLAPINZON",
    zipCode: "250810",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25875",
    city: "VILLETA",
    zipCode: "253418",
    type: "Rural",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25878",
    city: "VIOTA",
    zipCode: "252660",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25885",
    city: "YACOPI",
    zipCode: "253840",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25898",
    city: "ZIPACON",
    zipCode: "253010",
    type: "Urbano",
  },
  {
    departmentDANE: "25",
    department: "CUNDINAMARCA",
    cityDANE: "25899",
    city: "ZIPAQUIRA",
    zipCode: "250251",
    type: "Urbano",
  },
  {
    departmentDANE: "94",
    department: "GUAINIA",
    cityDANE: "94343",
    city: "BARRANCO MINAS",
    zipCode: "944010",
    type: "Urbano",
  },
  {
    departmentDANE: "94",
    department: "GUAINIA",
    cityDANE: "94886",
    city: "CACAHUAL",
    zipCode: "941010",
    type: "Urbano",
  },
  {
    departmentDANE: "94",
    department: "GUAINIA",
    cityDANE: "94001",
    city: "INIRIDA",
    zipCode: "940017",
    type: "Rural",
  },
  {
    departmentDANE: "94",
    department: "GUAINIA",
    cityDANE: "94663",
    city: "MAPIRIPANA",
    zipCode: "944058",
    type: "Rural",
  },
  {
    departmentDANE: "94",
    department: "GUAINIA",
    cityDANE: "94888",
    city: "MORICHAL",
    zipCode: "943059",
    type: "Rural",
  },
  {
    departmentDANE: "94",
    department: "GUAINIA",
    cityDANE: "94887",
    city: "PANA PANA",
    zipCode: "943018",
    type: "Rural",
  },
  {
    departmentDANE: "94",
    department: "GUAINIA",
    cityDANE: "94883",
    city: "SAN FELIPE",
    zipCode: "942010",
    type: "Urbano",
  },
  {
    departmentDANE: "95",
    department: "GUAVIARE",
    cityDANE: "95025",
    city: "EL RETORNO",
    zipCode: "951001",
    type: "Urbano",
  },
  {
    departmentDANE: "95",
    department: "GUAVIARE",
    cityDANE: "95001",
    city: "SAN JOSE DEL GUAVIARE",
    zipCode: "950001",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41006",
    city: "ACEVEDO",
    zipCode: "417079",
    type: "Rural",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41013",
    city: "AGRADO",
    zipCode: "414040",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41016",
    city: "AIPE",
    zipCode: "411001",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41020",
    city: "ALGECIRAS",
    zipCode: "413040",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41026",
    city: "ALTAMIRA",
    zipCode: "416020",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41078",
    city: "BARAYA",
    zipCode: "411060",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41132",
    city: "CAMPOALEGRE",
    zipCode: "413020",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41206",
    city: "COLOMBIA",
    zipCode: "411080",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41244",
    city: "ELIAS",
    zipCode: "417001",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41298",
    city: "GARZON",
    zipCode: "414027",
    type: "Rural",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41306",
    city: "GIGANTE",
    zipCode: "414001",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41349",
    city: "HOBO",
    zipCode: "413060",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41357",
    city: "IQUIRA",
    zipCode: "412060",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41359",
    city: "ISNOS",
    zipCode: "418048",
    type: "Rural",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41378",
    city: "LA ARGENTINA",
    zipCode: "415080",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41396",
    city: "LA PLATA",
    zipCode: "415078",
    type: "Rural",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41483",
    city: "NATAGA",
    zipCode: "415020",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41001",
    city: "NEIVA",
    zipCode: "410010",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41503",
    city: "OPORAPA",
    zipCode: "418001",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41518",
    city: "PAICOL",
    zipCode: "415040",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41524",
    city: "PALERMO",
    zipCode: "412001",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41548",
    city: "PITAL",
    zipCode: "414060",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41551",
    city: "PITALITO",
    zipCode: "417038",
    type: "Rural",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41615",
    city: "RIVERA",
    zipCode: "413001",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41660",
    city: "SALADOBLANCO",
    zipCode: "418020",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41668",
    city: "SAN AGUSTIN",
    zipCode: "418060",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41770",
    city: "SUAZA",
    zipCode: "416080",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41791",
    city: "TARQUI",
    zipCode: "416001",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41799",
    city: "TELLO",
    zipCode: "411040",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41801",
    city: "TERUEL",
    zipCode: "412040",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41797",
    city: "TESALIA",
    zipCode: "415001",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41807",
    city: "TIMANA",
    zipCode: "417010",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41872",
    city: "VILLAVIEJA",
    zipCode: "411020",
    type: "Urbano",
  },
  {
    departmentDANE: "41",
    department: "HUILA",
    cityDANE: "41885",
    city: "YAGUARA",
    zipCode: "412087",
    type: "Rural",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44078",
    city: "BARRANCAS",
    zipCode: "443040",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44090",
    city: "DIBULLA",
    zipCode: "446001",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44098",
    city: "DISTRACCION",
    zipCode: "444001",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44110",
    city: "EL MOLINO",
    zipCode: "444050",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44279",
    city: "FONSECA",
    zipCode: "444010",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44378",
    city: "HATONUEVO",
    zipCode: "443020",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44420",
    city: "LA JAGUA DEL PILAR",
    zipCode: "445040",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44430",
    city: "MAICAO",
    zipCode: "442001",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44001",
    city: "RIOHACHA",
    zipCode: "440001",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44650",
    city: "SAN JUAN DEL CESAR",
    zipCode: "444037",
    type: "Rural",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44847",
    city: "URIBIA",
    zipCode: "441020",
    type: "Urbano",
  },
  {
    departmentDANE: "44",
    department: "LA GUAJIRA",
    cityDANE: "44855",
    city: "URUMITA",
    zipCode: "445020",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47030",
    city: "ALGARROBO",
    zipCode: "472040",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47053",
    city: "ARACATACA",
    zipCode: "472007",
    type: "Rural",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47058",
    city: "ARIGUANI",
    zipCode: "475010",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47161",
    city: "CERRO SAN ANTONIO",
    zipCode: "476020",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47170",
    city: "CHIBOLO",
    zipCode: "476060",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47189",
    city: "CIENAGA",
    zipCode: "478002",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47245",
    city: "EL BANCO",
    zipCode: "473040",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47258",
    city: "EL PI\u00d1ON",
    zipCode: "476007",
    type: "Rural",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47268",
    city: "EL RETEN",
    zipCode: "478060",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47288",
    city: "FUNDACION",
    zipCode: "472020",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47318",
    city: "GUAMAL",
    zipCode: "473020",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47460",
    city: "NUEVA GRANADA",
    zipCode: "475020",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47541",
    city: "PEDRAZA",
    zipCode: "476040",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47545",
    city: "PIJI\u00d1O DEL CARMEN",
    zipCode: "474047",
    type: "Rural",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47551",
    city: "PIVIJAY",
    zipCode: "477050",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47555",
    city: "PLATO",
    zipCode: "475030",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47570",
    city: "PUEBLOVIEJO",
    zipCode: "478048",
    type: "Rural",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47605",
    city: "REMOLINO",
    zipCode: "477020",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47660",
    city: "SABANAS DE SAN ANGEL",
    zipCode: "475001",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47692",
    city: "SAN SEBASTIAN DE BUENAVISTA",
    zipCode: "473007",
    type: "Rural",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47703",
    city: "SAN ZENON",
    zipCode: "474060",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47707",
    city: "SANTA ANA",
    zipCode: "474020",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47720",
    city: "SANTA BARBARA DE PINTO",
    zipCode: "474001",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47001",
    city: "SANTA MARTA",
    zipCode: "470009",
    type: "Rural",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47745",
    city: "SITIONUEVO",
    zipCode: "477001",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47798",
    city: "TENERIFE",
    zipCode: "475057",
    type: "Rural",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47960",
    city: "ZAPAYAN",
    zipCode: "476050",
    type: "Urbano",
  },
  {
    departmentDANE: "47",
    department: "MAGDALENA",
    cityDANE: "47980",
    city: "ZONA BANANERA",
    zipCode: "478020",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50006",
    city: "ACACIAS",
    zipCode: "507008",
    type: "Rural",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50110",
    city: "BARRANCA DE UPIA",
    zipCode: "501007",
    type: "Rural",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50124",
    city: "CABUYARO",
    zipCode: "501011",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50150",
    city: "CASTILLA LA NUEVA",
    zipCode: "507041",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50223",
    city: "CUBARRAL",
    zipCode: "506001",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50226",
    city: "CUMARAL",
    zipCode: "501021",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50245",
    city: "EL CALVARIO",
    zipCode: "501041",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50251",
    city: "EL CASTILLO",
    zipCode: "506047",
    type: "Rural",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50270",
    city: "EL DORADO",
    zipCode: "506021",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50287",
    city: "FUENTE DE ORO",
    zipCode: "504021",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50350",
    city: "LA MACARENA",
    zipCode: "505021",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50400",
    city: "LEJANIAS",
    zipCode: "506067",
    type: "Rural",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50325",
    city: "MAPIRIPAN",
    zipCode: "503021",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50330",
    city: "MESETAS",
    zipCode: "505001",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50450",
    city: "PUERTO CONCORDIA",
    zipCode: "503041",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50568",
    city: "PUERTO GAITAN",
    zipCode: "502058",
    type: "Rural",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50577",
    city: "PUERTO LLERAS",
    zipCode: "503001",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50573",
    city: "PUERTO LOPEZ",
    zipCode: "502001",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50606",
    city: "RESTREPO",
    zipCode: "501031",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50680",
    city: "SAN CARLOS DE GUAROA",
    zipCode: "507011",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50683",
    city: "SAN JUAN DE ARAMA",
    zipCode: "504047",
    type: "Rural",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50686",
    city: "SAN JUANITO",
    zipCode: "501051",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50370",
    city: "URIBE",
    zipCode: "505041",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50001",
    city: "VILLAVICENCIO",
    zipCode: "500004",
    type: "Urbano",
  },
  {
    departmentDANE: "50",
    department: "META",
    cityDANE: "50711",
    city: "VISTAHERMOSA",
    zipCode: "504061",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52022",
    city: "ALDANA",
    zipCode: "524540",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52036",
    city: "ANCUYA",
    zipCode: "526007",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52051",
    city: "ARBOLEDA - BERRUECOS",
    zipCode: "520578",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52079",
    city: "BARBACOAS",
    zipCode: "528069",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52110",
    city: "BUESACO",
    zipCode: "520501",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52240",
    city: "CHACHAG\u00c3\u0153I",
    zipCode: "522001",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52203",
    city: "COLON",
    zipCode: "521067",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52207",
    city: "CONSACA",
    zipCode: "522548",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52210",
    city: "CONTADERO",
    zipCode: "523087",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52224",
    city: "CUASPUD",
    zipCode: "524560",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52227",
    city: "CUMBAL",
    zipCode: "525007",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52233",
    city: "CUMBITARA",
    zipCode: "526567",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52250",
    city: "EL CHARCO",
    zipCode: "527537",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52254",
    city: "EL PE\u00d1OL",
    zipCode: "522088",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52256",
    city: "EL ROSARIO",
    zipCode: "527037",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52258",
    city: "EL TABLON DE GOMEZ",
    zipCode: "520539",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52520",
    city: "FRANCISCO PIZARRO",
    zipCode: "528560",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52287",
    city: "FUNES",
    zipCode: "523520",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52317",
    city: "GUACHUCAL",
    zipCode: "524588",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52320",
    city: "GUAITARILLA",
    zipCode: "525508",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52323",
    city: "GUALMATAN",
    zipCode: "524501",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52352",
    city: "ILES",
    zipCode: "523060",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52354",
    city: "IMUES",
    zipCode: "523028",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52356",
    city: "IPIALES",
    zipCode: "524060",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52378",
    city: "LA CRUZ",
    zipCode: "521028",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52381",
    city: "LA FLORIDA",
    zipCode: "522048",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52385",
    city: "LA LLANADA",
    zipCode: "526507",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52390",
    city: "LA TOLA",
    zipCode: "527547",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52405",
    city: "LEIVA",
    zipCode: "527067",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52411",
    city: "LINARES",
    zipCode: "522508",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52418",
    city: "LOS ANDES",
    zipCode: "526527",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52427",
    city: "MAG\u00c3\u0153I",
    zipCode: "528001",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52435",
    city: "MALLAMA",
    zipCode: "525068",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52490",
    city: "OLAYA HERRERA",
    zipCode: "527569",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52506",
    city: "OSPINA",
    zipCode: "523047",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52001",
    city: "PASTO",
    zipCode: "520038",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52540",
    city: "POLICARPA",
    zipCode: "527001",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52560",
    city: "POTOSI",
    zipCode: "524039",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52573",
    city: "PUERRES",
    zipCode: "523548",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52585",
    city: "PUPIALES",
    zipCode: "524527",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52621",
    city: "ROBERTO PAYAN",
    zipCode: "528037",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52678",
    city: "SAMANIEGO",
    zipCode: "526049",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52835",
    city: "SAN ANDRES DE TUMACO",
    zipCode: "528528",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52019",
    city: "SAN JOSE DE ALBAN",
    zipCode: "521050",
    type: "Urbano",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52687",
    city: "SAN LORENZO",
    zipCode: "521548",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52694",
    city: "SAN PEDRO DE CARTAGO",
    zipCode: "521508",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52683",
    city: "SANDONA",
    zipCode: "522527",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52699",
    city: "SANTACRUZ",
    zipCode: "525579",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52720",
    city: "SAPUYES",
    zipCode: "525558",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52786",
    city: "TAMINANGO",
    zipCode: "521567",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52788",
    city: "TANGUA",
    zipCode: "523507",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52838",
    city: "TUQUERRES",
    zipCode: "525537",
    type: "Rural",
  },
  {
    departmentDANE: "52",
    department: "NARI\u00d1O",
    cityDANE: "52885",
    city: "YACUANQUER",
    zipCode: "523008",
    type: "Rural",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54003",
    city: "ABREGO",
    zipCode: "546070",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54051",
    city: "ARBOLEDAS",
    zipCode: "544550",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54099",
    city: "BOCHALEMA",
    zipCode: "543010",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54109",
    city: "BUCARASICA",
    zipCode: "545557",
    type: "Rural",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54128",
    city: "CACHIRA",
    zipCode: "546030",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54125",
    city: "CACOTA",
    zipCode: "544010",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54172",
    city: "CHINACOTA",
    zipCode: "541070",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54174",
    city: "CHITAGA",
    zipCode: "544030",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54206",
    city: "CONVENCION",
    zipCode: "547050",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54001",
    city: "CUCUTA",
    zipCode: "540019",
    type: "Rural",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54223",
    city: "CUCUTILLA",
    zipCode: "544520",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54239",
    city: "DURANIA",
    zipCode: "544517",
    type: "Rural",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54245",
    city: "EL CARMEN",
    zipCode: "547070",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54250",
    city: "EL TARRA",
    zipCode: "548050",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54261",
    city: "EL ZULIA",
    zipCode: "545510",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54313",
    city: "GRAMALOTE",
    zipCode: "545050",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54344",
    city: "HACARI",
    zipCode: "546510",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54347",
    city: "HERRAN",
    zipCode: "542017",
    type: "Rural",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54385",
    city: "LA ESPERANZA",
    zipCode: "546050",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54398",
    city: "LA PLAYA",
    zipCode: "546530",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54377",
    city: "LABATECA",
    zipCode: "542050",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54405",
    city: "LOS PATIOS",
    zipCode: "541010",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54418",
    city: "LOURDES",
    zipCode: "545070",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54480",
    city: "MUTISCUA",
    zipCode: "544070",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54498",
    city: "OCA\u00d1A",
    zipCode: "546552",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54518",
    city: "PAMPLONA",
    zipCode: "543050",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54520",
    city: "PAMPLONITA",
    zipCode: "543030",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54599",
    city: "RAGONVALIA",
    zipCode: "541050",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54660",
    city: "SALAZAR",
    zipCode: "544570",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54670",
    city: "SAN CALIXTO",
    zipCode: "547010",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54680",
    city: "SANTIAGO",
    zipCode: "545030",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54720",
    city: "SARDINATA",
    zipCode: "545530",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54743",
    city: "SILOS",
    zipCode: "544050",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54800",
    city: "TEORAMA",
    zipCode: "547030",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54810",
    city: "TIBU",
    zipCode: "548010",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54871",
    city: "VILLA CARO",
    zipCode: "546010",
    type: "Urbano",
  },
  {
    departmentDANE: "54",
    department: "NORTE DE SANTANDER",
    cityDANE: "54874",
    city: "VILLA DEL ROSARIO",
    zipCode: "541030",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86573",
    city: "LEGUIZAMO",
    zipCode: "864001",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86001",
    city: "MOCOA",
    zipCode: "860001",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86320",
    city: "ORITO",
    zipCode: "862001",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86568",
    city: "PUERTO ASIS",
    zipCode: "862060",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86569",
    city: "PUERTO CAICEDO",
    zipCode: "862080",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86571",
    city: "PUERTO GUZMAN",
    zipCode: "863001",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86757",
    city: "SAN MIGUEL",
    zipCode: "862040",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86749",
    city: "SIBUNDOY",
    zipCode: "861020",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86865",
    city: "VALLE DEL GUAMUEZ",
    zipCode: "862020",
    type: "Urbano",
  },
  {
    departmentDANE: "86",
    department: "PUTUMAYO",
    cityDANE: "86885",
    city: "VILLAGARZON",
    zipCode: "861080",
    type: "Urbano",
  },
  {
    departmentDANE: "63",
    department: "QUINDIO",
    cityDANE: "63130",
    city: "CALARCA",
    zipCode: "632001",
    type: "Urbano",
  },
  {
    departmentDANE: "63",
    department: "QUINDIO",
    cityDANE: "63190",
    city: "CIRCASIA",
    zipCode: "631001",
    type: "Urbano",
  },
  {
    departmentDANE: "63",
    department: "QUINDIO",
    cityDANE: "63272",
    city: "FILANDIA",
    zipCode: "634001",
    type: "Urbano",
  },
  {
    departmentDANE: "63",
    department: "QUINDIO",
    cityDANE: "63302",
    city: "GENOVA",
    zipCode: "632080",
    type: "Urbano",
  },
  {
    departmentDANE: "63",
    department: "QUINDIO",
    cityDANE: "63401",
    city: "LA TEBAIDA",
    zipCode: "633020",
    type: "Urbano",
  },
  {
    departmentDANE: "63",
    department: "QUINDIO",
    cityDANE: "63470",
    city: "MONTENEGRO",
    zipCode: "633007",
    type: "Rural",
  },
  {
    departmentDANE: "63",
    department: "QUINDIO",
    cityDANE: "63548",
    city: "PIJAO",
    zipCode: "632060",
    type: "Urbano",
  },
  {
    departmentDANE: "63",
    department: "QUINDIO",
    cityDANE: "63594",
    city: "QUIMBAYA",
    zipCode: "634027",
    type: "Rural",
  },
  {
    departmentDANE: "63",
    department: "QUINDIO",
    cityDANE: "63690",
    city: "SALENTO",
    zipCode: "631020",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66045",
    city: "APIA",
    zipCode: "663030",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66088",
    city: "BELEN DE UMBRIA",
    zipCode: "664047",
    type: "Rural",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66170",
    city: "DOSQUEBRADAS",
    zipCode: "661002",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66318",
    city: "GUATICA",
    zipCode: "664010",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66383",
    city: "LA CELIA",
    zipCode: "662030",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66400",
    city: "LA VIRGINIA",
    zipCode: "662001",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66440",
    city: "MARSELLA",
    zipCode: "661040",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66456",
    city: "MISTRATO",
    zipCode: "664020",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66001",
    city: "PEREIRA",
    zipCode: "660001",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66572",
    city: "PUEBLO RICO",
    zipCode: "663011",
    type: "Urbano",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66594",
    city: "QUINCHIA",
    zipCode: "664008",
    type: "Rural",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66682",
    city: "SANTA ROSA DE CABAL",
    zipCode: "661027",
    type: "Rural",
  },
  {
    departmentDANE: "66",
    department: "RISARALDA",
    cityDANE: "66687",
    city: "SANTUARIO",
    zipCode: "663001",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68013",
    city: "AGUADA",
    zipCode: "685521",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68051",
    city: "ARATOCA",
    zipCode: "682051",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68079",
    city: "BARICHARA",
    zipCode: "684041",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68081",
    city: "BARRANCABERMEJA",
    zipCode: "687032",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68001",
    city: "BUCARAMANGA",
    zipCode: "680008",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68132",
    city: "CALIFORNIA",
    zipCode: "680511",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68147",
    city: "CAPITANEJO",
    zipCode: "681541",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68152",
    city: "CARCASI",
    zipCode: "681521",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68160",
    city: "CEPITA",
    zipCode: "682061",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68162",
    city: "CERRITO",
    zipCode: "681501",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68167",
    city: "CHARALA",
    zipCode: "682551",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68169",
    city: "CHARTA",
    zipCode: "680551",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68179",
    city: "CHIPATA",
    zipCode: "685557",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68190",
    city: "CIMITARRA",
    zipCode: "686041",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68209",
    city: "CONFINES",
    zipCode: "683531",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68211",
    city: "CONTRATACION",
    zipCode: "683071",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68217",
    city: "COROMORO",
    zipCode: "682531",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68229",
    city: "CURITI",
    zipCode: "682041",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68235",
    city: "EL CARMEN DE CHUCURI",
    zipCode: "686561",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68245",
    city: "EL GUACAMAYO",
    zipCode: "683061",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68255",
    city: "EL PLAYON",
    zipCode: "687501",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68264",
    city: "ENCINO",
    zipCode: "682541",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68266",
    city: "ENCISO",
    zipCode: "681561",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68271",
    city: "FLORIAN",
    zipCode: "684541",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68276",
    city: "FLORIDABLANCA",
    zipCode: "681007",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68296",
    city: "GALAN",
    zipCode: "684051",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68298",
    city: "GAMBITA",
    zipCode: "683031",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68327",
    city: "G\u00c3\u0153EPSA",
    zipCode: "685547",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68307",
    city: "GIRON",
    zipCode: "687558",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68318",
    city: "GUACA",
    zipCode: "681031",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68322",
    city: "GUAPOTA",
    zipCode: "683017",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68324",
    city: "GUAVATA",
    zipCode: "684501",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68344",
    city: "HATO",
    zipCode: "683571",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68368",
    city: "JESUS MARIA",
    zipCode: "684551",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68370",
    city: "JORDAN",
    zipCode: "684011",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68377",
    city: "LA BELLEZA",
    zipCode: "685061",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68385",
    city: "LANDAZURI",
    zipCode: "686021",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68406",
    city: "LEBRIJA",
    zipCode: "687571",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68418",
    city: "LOS SANTOS",
    zipCode: "684001",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68425",
    city: "MACARAVITA",
    zipCode: "681531",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68432",
    city: "MALAGA",
    zipCode: "682011",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68444",
    city: "MATANZA",
    zipCode: "680561",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68464",
    city: "MOGOTES",
    zipCode: "682501",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68468",
    city: "MOLAGAVITA",
    zipCode: "682031",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68498",
    city: "OCAMONTE",
    zipCode: "682567",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68500",
    city: "OIBA",
    zipCode: "683021",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68502",
    city: "ONZAGA",
    zipCode: "682521",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68522",
    city: "PALMAR",
    zipCode: "683581",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68524",
    city: "PALMAS DEL SOCORRO",
    zipCode: "683541",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68533",
    city: "PARAMO",
    zipCode: "683527",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68547",
    city: "PIEDECUESTA",
    zipCode: "681012",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68549",
    city: "PINCHOTE",
    zipCode: "683511",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68572",
    city: "PUENTE NACIONAL",
    zipCode: "684521",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68573",
    city: "PUERTO PARRA",
    zipCode: "686001",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68575",
    city: "PUERTO WILCHES",
    zipCode: "687061",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68655",
    city: "SABANA DE TORRES",
    zipCode: "687007",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68673",
    city: "SAN BENITO",
    zipCode: "685531",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68679",
    city: "SAN GIL",
    zipCode: "684031",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68682",
    city: "SAN JOAQUIN",
    zipCode: "682511",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68684",
    city: "SAN JOSE DE MIRANDA",
    zipCode: "682021",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68689",
    city: "SAN VICENTE DE CHUCURI",
    zipCode: "686531",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68720",
    city: "SANTA HELENA DEL OPON",
    zipCode: "685501",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68745",
    city: "SIMACOTA",
    zipCode: "683561",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68755",
    city: "SOCORRO",
    zipCode: "683557",
    type: "Rural",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68770",
    city: "SUAITA",
    zipCode: "683041",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68780",
    city: "SURATA",
    zipCode: "680501",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68820",
    city: "TONA",
    zipCode: "680541",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68855",
    city: "VALLE DE SAN JOSE",
    zipCode: "682571",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68861",
    city: "VELEZ",
    zipCode: "685561",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68867",
    city: "VETAS",
    zipCode: "680531",
    type: "Urbano",
  },
  {
    departmentDANE: "68",
    department: "SANTANDER",
    cityDANE: "68895",
    city: "ZAPATOCA",
    zipCode: "684069",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70124",
    city: "CAIMITO",
    zipCode: "704010",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70230",
    city: "CHALAN",
    zipCode: "701017",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70204",
    city: "COLOSO",
    zipCode: "707030",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70215",
    city: "COROZAL",
    zipCode: "705039",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70221",
    city: "COVE\u00d1AS",
    zipCode: "706057",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70233",
    city: "EL ROBLE",
    zipCode: "705058",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70235",
    city: "GALERAS",
    zipCode: "702050",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70265",
    city: "GUARANDA",
    zipCode: "703070",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70418",
    city: "LOS PALMITOS",
    zipCode: "701050",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70429",
    city: "MAJAGUAL",
    zipCode: "703050",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70473",
    city: "MORROA",
    zipCode: "701078",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70508",
    city: "OVEJAS",
    zipCode: "701030",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70523",
    city: "PALMITO",
    zipCode: "706030",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70670",
    city: "SAMPUES",
    zipCode: "705079",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70678",
    city: "SAN BENITO ABAD",
    zipCode: "703010",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70702",
    city: "SAN JUAN DE BETULIA",
    zipCode: "705010",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70742",
    city: "SAN LUIS DE SINCE",
    zipCode: "702070",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70708",
    city: "SAN MARCOS",
    zipCode: "704037",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70713",
    city: "SAN ONOFRE",
    zipCode: "707018",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70717",
    city: "SAN PEDRO",
    zipCode: "702010",
    type: "Urbano",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70820",
    city: "SANTIAGO DE TOLU",
    zipCode: "706018",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70001",
    city: "SINCELEJO",
    zipCode: "700007",
    type: "Rural",
  },
  {
    departmentDANE: "70",
    department: "SUCRE",
    cityDANE: "70823",
    city: "TOLU VIEJO",
    zipCode: "707050",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73024",
    city: "ALPUJARRA",
    zipCode: "734560",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73026",
    city: "ALVARADO",
    zipCode: "730527",
    type: "Rural",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73030",
    city: "AMBALEMA",
    zipCode: "731001",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73043",
    city: "ANZOATEGUI",
    zipCode: "730540",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73055",
    city: "ARMERO",
    zipCode: "732060",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73067",
    city: "ATACO",
    zipCode: "735050",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73124",
    city: "CAJAMARCA",
    zipCode: "732507",
    type: "Rural",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73148",
    city: "CARMEN DE APICALA",
    zipCode: "733590",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73152",
    city: "CASABIANCA",
    zipCode: "731520",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73168",
    city: "CHAPARRAL",
    zipCode: "735569",
    type: "Rural",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73200",
    city: "COELLO",
    zipCode: "733501",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73217",
    city: "COYAIMA",
    zipCode: "735020",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73226",
    city: "CUNDAY",
    zipCode: "734040",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73236",
    city: "DOLORES",
    zipCode: "734540",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73268",
    city: "ESPINAL",
    zipCode: "733529",
    type: "Rural",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73270",
    city: "FALAN",
    zipCode: "732001",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73275",
    city: "FLANDES",
    zipCode: "733510",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73283",
    city: "FRESNO",
    zipCode: "731560",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73319",
    city: "GUAMO",
    zipCode: "733549",
    type: "Rural",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73347",
    city: "HERVEO",
    zipCode: "731540",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73349",
    city: "HONDA",
    zipCode: "732040",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73001",
    city: "IBAGUE",
    zipCode: "730010",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73352",
    city: "ICONONZO",
    zipCode: "734028",
    type: "Rural",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73408",
    city: "LERIDA",
    zipCode: "731020",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73411",
    city: "LIBANO",
    zipCode: "731048",
    type: "Rural",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73443",
    city: "MARIQUITA",
    zipCode: "732020",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73449",
    city: "MELGAR",
    zipCode: "734001",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73461",
    city: "MURILLO",
    zipCode: "731060",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73483",
    city: "NATAGAIMA",
    zipCode: "735001",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73504",
    city: "ORTEGA",
    zipCode: "735501",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73520",
    city: "PALOCABILDO",
    zipCode: "731580",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73547",
    city: "PIEDRAS",
    zipCode: "730501",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73555",
    city: "PLANADAS",
    zipCode: "735070",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73563",
    city: "PRADO",
    zipCode: "734520",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73585",
    city: "PURIFICACION",
    zipCode: "734501",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73616",
    city: "RIOBLANCO",
    zipCode: "735580",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73622",
    city: "RONCESVALLES",
    zipCode: "735550",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73624",
    city: "ROVIRA",
    zipCode: "733040",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73671",
    city: "SALDA\u00d1A",
    zipCode: "733578",
    type: "Rural",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73675",
    city: "SAN ANTONIO",
    zipCode: "735530",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73686",
    city: "SANTA ISABEL",
    zipCode: "730560",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73854",
    city: "VALLE DE SAN JUAN",
    zipCode: "733020",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73861",
    city: "VENADILLO",
    zipCode: "730580",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73870",
    city: "VILLAHERMOSA",
    zipCode: "731501",
    type: "Urbano",
  },
  {
    departmentDANE: "73",
    department: "TOLIMA",
    cityDANE: "73873",
    city: "VILLARRICA",
    zipCode: "734060",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76020",
    city: "ALCALA",
    zipCode: "762040",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76036",
    city: "ANDALUCIA",
    zipCode: "763010",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76041",
    city: "ANSERMANUEVO",
    zipCode: "762018",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76109",
    city: "BUENAVENTURA",
    zipCode: "764501",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76113",
    city: "BUGALAGRANDE",
    zipCode: "763008",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76122",
    city: "CAICEDONIA",
    zipCode: "762547",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76001",
    city: "CALI",
    zipCode: "760008",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76126",
    city: "CALIMA",
    zipCode: "760537",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76147",
    city: "CARTAGO",
    zipCode: "762021",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76233",
    city: "DAGUA",
    zipCode: "760520",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76243",
    city: "EL AGUILA",
    zipCode: "762001",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76246",
    city: "EL CAIRO",
    zipCode: "761501",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76248",
    city: "EL CERRITO",
    zipCode: "763520",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76250",
    city: "EL DOVIO",
    zipCode: "761560",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76275",
    city: "FLORIDA",
    zipCode: "763568",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76306",
    city: "GINEBRA",
    zipCode: "763517",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76318",
    city: "GUACARI",
    zipCode: "763501",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76111",
    city: "GUADALAJARA DE BUGA",
    zipCode: "763047",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76364",
    city: "JAMUNDI",
    zipCode: "764001",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76377",
    city: "LA CUMBRE",
    zipCode: "760510",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76497",
    city: "OBANDO",
    zipCode: "762501",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76520",
    city: "PALMIRA",
    zipCode: "763537",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76563",
    city: "PRADERA",
    zipCode: "763558",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76616",
    city: "RIOFRIO",
    zipCode: "761030",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76622",
    city: "ROLDANILLO",
    zipCode: "761558",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76736",
    city: "SEVILLA",
    zipCode: "762538",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76823",
    city: "TORO",
    zipCode: "761520",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76828",
    city: "TRUJILLO",
    zipCode: "761020",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76834",
    city: "TULUA",
    zipCode: "763029",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76845",
    city: "ULLOA",
    zipCode: "762030",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76863",
    city: "VERSALLES",
    zipCode: "761537",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76869",
    city: "VIJES",
    zipCode: "760550",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76890",
    city: "YOTOCO",
    zipCode: "761040",
    type: "Urbano",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76892",
    city: "YUMBO",
    zipCode: "760507",
    type: "Rural",
  },
  {
    departmentDANE: "76",
    department: "VALLE DEL CAUCA",
    cityDANE: "76895",
    city: "ZARZAL",
    zipCode: "762527",
    type: "Rural",
  },
  {
    departmentDANE: "97",
    department: "VAUPES",
    cityDANE: "97161",
    city: "CARURU",
    zipCode: "973001",
    type: "Urbano",
  },
  {
    departmentDANE: "97",
    department: "VAUPES",
    cityDANE: "97001",
    city: "MITU",
    zipCode: "970001",
    type: "Urbano",
  },
  {
    departmentDANE: "97",
    department: "VAUPES",
    cityDANE: "97511",
    city: "PACOA",
    zipCode: "972007",
    type: "Rural",
  },
  {
    departmentDANE: "97",
    department: "VAUPES",
    cityDANE: "97777",
    city: "PAPUNAUA",
    zipCode: "973047",
    type: "Rural",
  },
  {
    departmentDANE: "97",
    department: "VAUPES",
    cityDANE: "97666",
    city: "TARAIRA",
    zipCode: "972040",
    type: "Urbano",
  },
  {
    departmentDANE: "97",
    department: "VAUPES",
    cityDANE: "97889",
    city: "YAVARATE",
    zipCode: "971007",
    type: "Rural",
  },
  {
    departmentDANE: "99",
    department: "VICHADA",
    cityDANE: "99773",
    city: "CUMARIBO",
    zipCode: "991001",
    type: "Urbano",
  },
  {
    departmentDANE: "99",
    department: "VICHADA",
    cityDANE: "99524",
    city: "LA PRIMAVERA",
    zipCode: "992001",
    type: "Urbano",
  },
  {
    departmentDANE: "99",
    department: "VICHADA",
    cityDANE: "99001",
    city: "PUERTO CARRE\u00d1O",
    zipCode: "990001",
    type: "Urbano",
  },
  {
    departmentDANE: "99",
    department: "VICHADA",
    cityDANE: "99624",
    city: "SANTA ROSALIA",
    zipCode: "992050",
    type: "Urbano",
  },
];