export const documentTypes = [
  // {
  //   id: 11,
  //   type: "Registro Civil",
  // },
  // {
  //   id: 1,
  //   type: "Tarjeta de Identidad",
  // },
  {
    id: 2,
    type: "Cédula de Ciudadanía",
  },
  {
    id: 3,
    type: "Tarjeta de Extranjería",
  },
  {
    id: 4,
    type: "Cédula de Extranjería",
  },
  {
    id: 5,
    type: "NIT",
  },
  {
    id: 6,
    type: "Pasaporte",
  },
  {
    id: 7,
    type: "Documento de Identificación Extranjero",
  },
  {
    id: 12,
    type: "NUIP",
  },
  {
    id: 13,
    type: "No obligado a registrarse en el RUT",
  },
];

export const contributorTypes = [
  {
    id: 1,
    type: "Régimen simplificado",
  },
  {
    id: 2,
    type: "Régimen común",
  },
  {
    id: 3,
    type: "No determinado",
  },
];
